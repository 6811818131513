import React from 'react';
import Helmet from 'react-helmet';

/**
 * Entry point component for the index page and post pages.
 * @param {React.HTMLProps<any>} props
 * @return {React.ReactElement}
 */
const Main = (props: React.HTMLProps<any>): React.ReactElement =>
  <>
    <Helmet>
      <body
        className="zh_CN wx_wap_page mm_appmsg comment_feature discuss_tab
    appmsg_skin_default appmsg_style_default pages_skin_pc not_in_mm"
      />
    </Helmet>
    <main>
      {props.children}
    </main>
  </>;

export default Main;

import React from 'react';

/**
 * Rich media wrapper.
 * @param {React.HTMLProps<React.ReactNode>} props
 * @return {React.ReactElement}
 */
const RichMedia = (props: React.HTMLProps<React.ReactNode>):
  React.ReactElement =>
  // The following contents are grabbed from WeChat Massive Platform source.
  <div id="js_article" className="rich_media" wah-hotarea="click">
    <div className="rich_media_inner">
      <div id="page-content" className="rich_media_area_primary">
        <div className="rich_media_area_primary_inner">
          <div id="img-content" className="rich_media_wrp">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  </div>;

export default RichMedia;
